import { APP_INITIALIZER, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./home-page/home-page.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AdminComponent } from "./admin/admin.component";
import { EprComponent } from "./epr/epr.component";
import { EprDeactivateGuard } from "./guards/EprDeactivateGuard";
import { AdminGuard } from "./guards/AdminGuard";
import { FiletrackerDashboardComponent } from "./filetracker-dashboard/filetracker-dashboard.component";
import { FiletrackerComponent } from "./filetracker/filetracker.component";
import { AdminDeactivateGuard } from "./guards/AdminDeactivateGuard";
import { SpedmDashboardComponent } from "./spedm-dashboard/spedm-dashboard.component";
import { SpedmOnboardingComponent } from "./spedm-onboarding/spedm-onboarding.component";
import { environment } from "../environments/environment";
import { RteComponent } from "./rte/rte.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { EprActivateGuard } from "./guards/EprActivateGuard";
import { CopayAccumDashboardComponent } from "./copay-accum-dashboard/copay-accum-dashboard.component";
import { CopayAccumOnboardingComponent } from "./copay-accum-onboarding/copay-accum-onboarding.component";
import { EnvironmentService } from "./common/environment-service/environment.service";
import { EprRouteResolver } from "./helpers/epr-routing-helper";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomePageComponent, canActivate: [AdminGuard] },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AdminGuard],
    canDeactivate: [AdminDeactivateGuard],
  },
  {
    path: "userSettings",
    component: UserSettingsComponent,
    canActivate: [AdminGuard],
    canDeactivate: [AdminDeactivateGuard],
  },
  {
    path: "dashboard/filetracker",
    component: FiletrackerDashboardComponent,
    canActivate: [AdminGuard],
  },
  { path: "dashboard/onboardingspedm", component: SpedmOnboardingComponent },
  { path: "dashboard/spedm", component: SpedmDashboardComponent },
  {
    path: "dashboard/onboardingcopay",
    component: CopayAccumOnboardingComponent,
  },
  { path: "dashboard/Benefit", component: CopayAccumDashboardComponent },
  {
    path: "home/dashboard/filetracker",
    component: FiletrackerDashboardComponent,
  },
  {
    path: "myPBM",
    component: HomePageComponent,
    resolve: {
      url: "externalUrlRedirectResolver",
    },
    data: {
      externalUrl: environment.myPBM,
    },
  },
  // Remove the below file tracker path once moved to separate repo
  { path: "filetracker", component: FiletrackerComponent },
  {
    path: "spedm",
    component: SpedmDashboardComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "benefit",
    component: CopayAccumOnboardingComponent,
    canActivate: [AdminGuard],
  },
  { path: "rte", component: RteComponent, canActivate: [AdminGuard] },
  {
    path: "epr",
    component: EprComponent,
    canDeactivate: [EprDeactivateGuard],
    canActivate: [EprActivateGuard],
    resolve: {
      data: EprRouteResolver,
    },
  },
  {
    path: "user-setting-lib",
    loadChildren: () =>
      import("@ewf-mfe/user-setting-mfe-library-v1").then(
        (m) => m.UserSettingMfeLibraryModule
      ),
  },
  {
    path: "inquiry-lib",
    loadChildren: () =>
      import("@ewf-mfe/ewf-mbr-grp-inq-mfe-library").then(
        (m) => m.EwfMbrGrpInqMfeLibraryModule
      ),
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  providers: [
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: EnvironmentService) => () => ds.applyOverrides(),
      deps: [EnvironmentService],
      multi: true,
    },
  ],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
